<template>
    <div class="ChartRadar mt-40">
        <div class="RadarChart">
            <div class="HeaderRadarChart ">
                <div class="TitleRadarChart">
                    {{$t("Report.NumberOfVisitor").toUpperCase()}}
                    </div>
                    <div class="Btn-RadarChart"
                        @click="chooseRadarChartOption()"
                        v-click-outside="handleClickOutSideRadarChart">
                        {{typeOfCalculatorRadarChart}}
                        <span class="material-icons ml-8">arrow_drop_down</span>
                        <div
                            ref="wrapRadarChartOption"
                            class="wrapOption"
                        >
                            <div
                                class="Option"
                                @click="changeOptionRadarChart('TotalAmount')"
                            >
                                {{ $t("Report.TotalAmount") }}
                            </div>
                            <div
                                class="Option"
                                @click="changeOptionRadarChart('AverageAmount')"
                            >
                                {{ $t("Report.AverageAmount") }}
                            </div>
                        </div>
                    </div>
            </div>
            <div id="chart" class="retailRadar" style="margin-left:-35px">
                <apexchart type="radar" height="460" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
import { Api } from "@/api/index";
const totalGateInWeek = Api.get("totalGateInWeek")

export default {
    name: "RadarCustomer",
    props: {
        dateRange: {
            type: Object
        },
        choosenGroupID: {
            type: String
        }
    },
    data() {
        let self = this
        return {
            chartOptions: {},
            listSegmentAnalystData: [],
            typeOfCalculatorRadarChart: self.$t("Report.AverageAmount"),
            series: [],
            dateStart: 0,
            dateEnd: 0,
        };
    },
    methods: {
        changeOptionRadarChart(option) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideRadarChart();
            }, 100);
            this.typeOfCalculatorRadarChart = this.$t("Report." + option);
            this.getAPITotalGateInWeek()
            
        },
        getAPITotalGateInWeek() {
            let isAverage = true
            if (this.typeOfCalculatorRadarChart == this.$t("Report.TotalAmount")) {
                isAverage = false
            }
            totalGateInWeek.get(this.choosenGroupID, this.dateStart, this.dateEnd, isAverage).then(response => {
                this.series = [{
                    name: this.$t("Report.WeekDay"),
                    data: [response.data.weekdays.morning, response.data.weekdays.evening, response.data.weekdays.afternoon]
                }, {
                    name: this.$t("Report.WeekEnd"),
                    data: [response.data.weekend.morning, response.data.weekend.evening, response.data.weekend.afternoon]
                }]
            })

        },
        chooseRadarChartOption() {
            this.$refs["wrapRadarChartOption"].style.position = "absolute";
            this.$refs["wrapRadarChartOption"].style.display = "block";
            this.$refs["wrapRadarChartOption"].style.visibility = "visible";
        },
        handleClickOutSideRadarChart() {
            this.$refs["wrapRadarChartOption"].style.display = "none";
            this.$refs["wrapRadarChartOption"].style.visibility = "hidden";
        },
    },
    mounted() {
        this.dateStart = this.dateRange.dateStart
        this.dateEnd = this.dateRange.dateEnd
        this.getAPITotalGateInWeek()
    },
    watch: {
        dateRange: {
            handler: function(val) {
                this.dateStart = val["dateStart"]
                this.dateEnd = val["dateEnd"]
                this.getAPITotalGateInWeek()
            },
            deep: true
        },
        choosenGroupID() {
            this.getAPITotalGateInWeek()
        },
        series(val) {
            var categories = ['Sáng', "Tối", "Chiều"]
            if (localStorage.lang == "en") {
                categories = ['Morning', "Evening", "Afternoon"]
            }
            let self = this
            this.chartOptions = {
                grid: {
		        	show: false,
                    padding: {
                	    top: -20,
                        bottom: -130
                    }
               },
                chart: {
                    height: 422,
                    type: 'radar',
                    events: {
                        legendClick: function(chartContext, seriesIndex, config) {
                            console.log(seriesIndex);
                        }
                    }
                },
                colors: ["#276EFF", "#FFB900"],
                legend: {
                    // show: false
                    position: 'bottom',
                    markers: {
                        radius: 6,
                    },
                    fontWeight: 400,
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                    onItemClick: {
                        toggleDataSeries: false,
                        highlightDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                },
                title: {
                    text: 'Basic Radar Chart'
                },
                xaxis: {
                    categories: categories,
                    labels: {
                        trim: true,
                        style: {
                            fontFamily: "Roboto",
                            fontSize: '16px',
                            fontWeight: 400,
                            colors: ["#000000", "#000000", "#000000"]
                        },
                        rotate: 0,
                        maxHeight: 120,
                        offsetX: 8,
                    },
                },
                yaxis: {
                    show: false
                },
                tooltip: {
                    marker: {
                        show: false,
                    },
                    y: {
                        title: {
                            formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                                var content = ""
                                if (value == self.$t("Report.WeekEnd")) {
                                    var inWeekDayExist = false
                                    for (var valueOfSeries in series[0]) {
                                        
                                        if ((parseInt(series[seriesIndex][dataPointIndex]) == series[0][valueOfSeries] && dataPointIndex == valueOfSeries) ||
                                            (parseInt(series[seriesIndex][dataPointIndex]) == series[0][valueOfSeries] && series[0][valueOfSeries] == 0)) {
                                            if (inWeekDayExist == false) {
                                                inWeekDayExist = true 
                                                content = "<p class='title-tooltip'>" + self.$t("Report.WeekDay") + "</p>" + "<br>"
                                            }
                                            content = content + "<p class='text-tooltip'>" + categories[valueOfSeries]  + ": " + "<span class='title-tooltip'>" + parseInt(series[seriesIndex][dataPointIndex]).toLocaleString("en-US")  + " " + self.$t("Report.CustomerRepack") + "</span>" + "</p>" + "<br>"
                                        }
                                    }
                                    if (content != "") { 
                                        content = content + "<p style='margin-top: 26px' class='title-tooltip'>" + self.$t("Report.WeekEnd") + "</p>" + "<br>"
                                    } else {
                                        content = content + "<p class='title-tooltip'>" + self.$t("Report.WeekEnd") + "</p>" + "<br>"
                                    }
                                    
                                    for (var valueOfSeries in series[1]) {
                                        if ((parseInt(series[seriesIndex][dataPointIndex]) == series[1][valueOfSeries] && dataPointIndex == valueOfSeries) ||
                                        (parseInt(series[seriesIndex][dataPointIndex]) == series[1][valueOfSeries] && series[1][valueOfSeries] == 0)) {
                                            content = content + "<p class='text-tooltip'>" + categories[valueOfSeries]  + ": " + "<span class='title-tooltip'>" + parseInt(series[seriesIndex][dataPointIndex]).toLocaleString("en-US") + " " + self.$t("Report.CustomerRepack") + "</span>" + "</p>" + "<br>"
                                        }
                                    }
                                } else {
                                    content = "<p class='title-tooltip'>" + self.$t("Report.WeekDay") + "</p>" + "<br>"
                                    for (var valueOfSeries in series[0]) {
                                        if ((parseInt(series[seriesIndex][dataPointIndex]) == series[0][valueOfSeries] && dataPointIndex == valueOfSeries) ||
                                        (parseInt(series[seriesIndex][dataPointIndex]) == series[0][valueOfSeries] && series[0][valueOfSeries] == 0)) {
                                            content = content + "<p class='text-tooltip'>" + categories[valueOfSeries]  + ": " + "<span class='title-tooltip'>" + parseInt(series[seriesIndex][dataPointIndex]).toLocaleString("en-US")  + " " + self.$t("Report.CustomerRepack") + "</span>" + "</p>" + "<br>"
                                        }
                                    }

                                    var inWeekendExist = false
                                    for (var valueOfSeries in series[1]) {
                                        if ((parseInt(series[seriesIndex][dataPointIndex]) == series[1][valueOfSeries] && dataPointIndex == valueOfSeries) ||
                                        (parseInt(series[seriesIndex][dataPointIndex]) == series[1][valueOfSeries] && series[1][valueOfSeries] == 0))    {
                                            if (inWeekendExist == false) {
                                                inWeekendExist = true 
                                                content = content + "<p style='margin-top: 26px' class='title-tooltip'>" + self.$t("Report.WeekEnd") + "</p>" + "<br>"
                                            }
                                            content = content + "<p class='text-tooltip'>" + categories[valueOfSeries]  + ": " + "<span class='title-tooltip'>" + parseInt(series[seriesIndex][dataPointIndex]).toLocaleString("en-US")  + " " + self.$t("Report.CustomerRepack") + "</span>" + "</p>" + "<br>"
                                        }
                                    }
                                }
                                return content
                                
                            }
                        },
                        style: {
                            fontSize:  '14px',
                            fontFamily: "Roboto",
                            color:  '#000000'
                        },
                        formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                            return ""
                        },
                    },
                    x: {
                        show: false
                    }
                }
            }
        }
    }
};
</script>
<style lang="scss">
.RadarChart {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 24px;
    .HeaderRadarChart {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .TitleRadarChart {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1F1F1F;
        }
        .Btn-RadarChart {
            padding: 8px 8px;
            border: 1px solid #8C8C8C;
            border-radius: 8px;
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #434343;
            position: relative;
            margin-top: -8px;
            cursor: pointer;
            .wrapOption {
                overflow: auto;
                background: #ffffff;
                box-sizing: border-box;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                position: absolute;
                width: 250px !important;
                top: 43px;
                z-index: 9999;
                // visibility: hidden;
                max-height: 312px;
                overflow: auto;
                .Option {
                    padding: 16px;
                    font-family: "Roboto";
                    font-size: 14px;
                    line-height: 150%;
                    color: #333333;
                    min-width: 180px;
                    font-weight: 400;
                    cursor: pointer;
                    &:hover {
                        background-color: #f6f9ff;
                    }
                }
            }
        }
    }
}
.retailRadar {
    .apexcharts-tooltip-title {
        background: #FFFFFF !important;
        color: #FFFFFF !important;
        border-radius-topleft: 5px;
        border-radius-topright: 5px;
    }
    .apexcharts-legend-marker {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 3px;
        border-style: solid;
        margin-left: 16px;
    }
}

.title-tooltip {
    font-size:  14px !important;
    font-family: 'Roboto' !important;
    color: #000000;
    font-weight: 500 !important;
    margin-bottom: -10px !important;
    
}

.text-tooltip {
    font-size:  14px !important;
    font-family: 'Roboto' !important;
    color: #000000;
    font-weight: 400 !important;
    margin-bottom: -30px !important; 
}
</style>