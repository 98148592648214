<template>
    <div class="wrapTreeMappRushhour">
        <fusioncharts
            :type="type"
            :width="width"
            :height="height"
            :dataformat="dataFormat"
            :dataSource="dataSource"
            ref="heatmapchart"
        >
        </fusioncharts>
    </div>
</template>

<script>
import * as moment from "moment-timezone";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

export default {
    props: {
        reportType: String,
        listDateRange: Array,
        rawData: Array,
        listDateRangeString: Array
    },
    watch: {
        rawData(val) {
            if (this.reportType == "retail") {
                console.log(window.innerWidth)
                this.$refs.heatmapchart.chartObj.width = window.innerWidth - 776
            } else {
                this.$refs.heatmapchart.chartObj.width = window.innerWidth / 2.4
            }
            
            // find column
            let collumn = [{
                "id": "07",
                "label": "7h-8h"
            },
            {
                "id": "08",
                "label": "8h-9h"
            },
            {
                "id": "09",
                "label": "9h-10h"
            },
            {
                "id": "10",
                "label": "10h-11h"
            },
            {
                "id": "11",
                "label": "11h-12h"
            },
            {
                "id": "12",
                "label": "12h-13h"
            },
            {
                "id": "13",
                "label": "13h-14h"
            },
            {
                "id": "14",
                "label": "14h-15h"
            },
            {
                "id": "15",
                "label": "15h-16h"
            },
            {
                "id": "16",
                "label": "16h-17h"
            },
            {
                "id": "17",
                "label": "17h-18h"
            },
            {
                "id": "18",
                "label": "18h-19h"
            },
            {
                "id": "19",
                "label": "19h-20h"
            },
            {
                "id": "20",
                "label": "20h-21h"
            },
            {
                "id": "21",
                "label": "21h-22h"
            },
            {
                "id": "22",
                "label": "22h-23h"
            },
            {
                "id": "23",
                "label": "23h-24h"
            }]

            // create row
            let rowData = {}
            let rowValue = []
            
            for (let i = 0; i < this.listDateRange.length; i++) {
                let data = {
                    id: this.listDateRange[i],
                    label: this.listDateRange[i].split(" /")[0]
                }
                rowValue.push(data)
            }
            rowData.row = rowValue
            // find max value
            let maxValue = 0
            for (let i = 0; i < this.rawData.length; i++) {
                for (let z = 0; z < this.rawData[i].length; z++) {
                    if (maxValue < this.rawData[i][z]) {
                        maxValue = this.rawData[i][z]
                    }
                }
            }

            // find rangeColor
            // chia ra các khoảng rồi tìm bội số 5 gần nhất của nó để làm tròn về các số 0 hoặc 5
            let colorRange = {}
            let color = []
            let numberRange = []
            if (maxValue <= 4 && maxValue > 0) {
                let rangeOne = {
                    "code": "#F0F0F0",
                    "minValue": "0",
                    "maxValue": (maxValue/3*1).toString().slice(0,4),
                    "label": "0" + " - " + (maxValue/3*1).toString().slice(0,4) 
                }

                let rangeTwo = {
                    "code": "#68A1D9",
                    "minValue": (maxValue/3*1).toString().slice(0,4),
                    "maxValue": (maxValue/3*2).toString().slice(0,4),
                    "label": (maxValue/3*1+0.01).toString().slice(0,4) + " - " + (maxValue/3*2).toString().slice(0,4)
                }

                let rangeThree = {
                    "code": "#064B6C",
                    "minValue": (maxValue/3*2).toString().slice(0,4),
                    "maxValue": (maxValue).toString().slice(0,4),
                    "label": (maxValue/3*2+0.01).toString().slice(0,4) + " - " + (maxValue).toString().slice(0,4)
                }
                color = [rangeOne, rangeTwo, rangeThree]
            } else if (maxValue > 4 && maxValue < 25) {
                let rangeOne = {
                    "code": "#F0F0F0",
                    "minValue": "0",
                    "maxValue": Math.ceil(maxValue/3*1).toString(),
                    "label": "0" + " - " + Math.ceil(maxValue/3*1).toString()
                }

                let rangeTwo = {
                    "code": "#68A1D9",
                    "minValue": (Math.ceil(maxValue/3*1)).toString(),
                    "maxValue": Math.ceil(maxValue/3*2).toString(),
                    "label": (Math.ceil(maxValue/3*1)+1).toString() + " - " + Math.ceil(maxValue/3*2).toString()
                }

                let rangeThree = {
                    "code": "#064B6C",
                    "minValue": (Math.ceil(maxValue/3*2)).toString(),
                    "maxValue": Math.ceil(maxValue).toString(),
                    "label": (Math.ceil(maxValue/3*2)+1).toString() + " - " + Math.ceil(maxValue).toString()
                }
                color = [rangeOne, rangeTwo, rangeThree]
            } else if (maxValue >= 25) {
                numberRange = [this.round5(Math.ceil(maxValue/3*1)), this.round5(Math.ceil(maxValue/3*2)), this.round5(Math.ceil(maxValue))]
                let rangeOne = {
                    "code": "#F0F0F0",
                    "minValue": "0",
                    "maxValue": this.round5(Math.ceil(maxValue/3*1)).toString(),
                    "label": "0" + " - " + numberRange[0].toLocaleString('en-US')
                }

                let rangeTwo = {
                    "code": "#68A1D9",
                    "minValue": numberRange[0].toString(),
                    "maxValue": numberRange[1].toString(),
                    "label": (numberRange[0]+1).toLocaleString('en-US') + " - " + numberRange[1].toLocaleString('en-US')
                }

                let rangeThree = {
                    "code": "#064B6C",
                    "minValue": numberRange[1].toString(),
                    "maxValue": numberRange[2].toString(),
                    "label": (numberRange[1]+1).toLocaleString('en-US') + " - " + numberRange[2].toLocaleString('en-US')
                }
                color = [rangeOne, rangeTwo, rangeThree]
            } else {
                let rangeOne = {
                    "code": "#F0F0F0",
                    "minValue": "0",
                    "maxValue": "0",
                    "label": "0 - 0"
                }

                let rangeTwo = {
                    "code": "#68A1D9",
                    "minValue": "0",
                    "maxValue": "0",
                    "label": "0 - 0"
                }

                let rangeThree = {
                    "code": "#064B6C",
                    "minValue": "0",
                    "maxValue": "0",
                    "label": "0 - 0"
                }
                color = [rangeOne, rangeTwo, rangeThree]
            }
            colorRange = {
                "gradient": "0",
                "color": color
            }


            // find dataset
            let dataset = []
            let datasetValue = []
            for (let i = 0; i < this.rawData.length; i++) {
                for (let z = 0; z < this.rawData[i].length; z++) {
                    let data = {
                        "rowid": rowValue[i].id,
                        "columnid": collumn[z].id,
                        "displayValue": this.rawData[i][z].toLocaleString('en-US'),
                    }
                    if (this.reportType == "retail") {
                        data = {
                            "rowid": rowValue[i].id,
                            "columnid": collumn[z].id,
                            "displayValue": this.rawData[i][z].toLocaleString('en-US'),
                            "tllabel": this.listDateRangeString[i]
                        }
                    }
                    
                    
                    if (maxValue <= 4 && maxValue > 0) {
                        if (0 <= this.rawData[i][z] && this.rawData[i][z] <= maxValue/3*1) {
                            data.colorRangeLabel = "0" + " - " + (maxValue/3*1).toString().slice(0,4)
                        } else if (maxValue/3*1 < this.rawData[i][z] && this.rawData[i][z] <= maxValue/3*2) {
                            data.colorRangeLabel = (maxValue/3*1+0.01).toString().slice(0,4) + " - " + (maxValue/3*2).toString().slice(0,4)
                        } else if (maxValue/3*2 < this.rawData[i][z] && this.rawData[i][z] <= maxValue) {
                            data.colorRangeLabel = (maxValue/3*2+0.01).toString().slice(0,4) + " - " + maxValue
                        }
                    } else if (maxValue > 4 && maxValue < 25) {
                        if (0 <= this.rawData[i][z] && this.rawData[i][z] <= Math.ceil(maxValue/3*1)) {
                            data.colorRangeLabel = "0" + " - " + Math.ceil(maxValue/3*1).toString()
                        } else if (Math.ceil(maxValue/3*1) < this.rawData[i][z] && this.rawData[i][z] <= Math.ceil(maxValue/3*2)) {
                            data.colorRangeLabel = (Math.ceil(maxValue/3*1)+1).toString() + " - " + Math.ceil(maxValue/3*2).toString()
                        } else if (Math.ceil(maxValue/3*2) < this.rawData[i][z] && this.rawData[i][z] <= maxValue) {
                            data.colorRangeLabel = (Math.ceil(maxValue/3*2)+1).toString() + " - " + maxValue
                        }
                    } else {
                        if (0 <= this.rawData[i][z] && this.rawData[i][z] <= numberRange[0]) {
                            data.colorRangeLabel = color[0]["label"]
                        } else if (numberRange[0] < this.rawData[i][z] && this.rawData[i][z] <= numberRange[1]) {
                            data.colorRangeLabel = color[1]["label"]
                        } else if (numberRange[1] < this.rawData[i][z] && this.rawData[i][z] <= maxValue) {
                            data.colorRangeLabel = color[2]["label"]
                        } else {
                            data.colorRangeLabel = color[0]["label"]
                        }
                    }
                    
                    datasetValue.push(data)
                }
            }
            dataset = [{
                "data": datasetValue
            }]
            let slantLabel = "1"
            let labelDisplay = "rotate"
            let rotateLabels = "1"
            let xAxisPosition = "top"
            let plottooltext = "$rowLabel : $displayValue " + this.$t("Report.People")
            if (this.reportType == "retail") {
                slantLabel = "0"
                labelDisplay = "auto"
                rotateLabels = "0"
                xAxisPosition = "bottom"
                plottooltext = "<p style='font-size: 12px; font-weight: 400; line-height: 16px; color: #1F1F1F'>$tlLabel: $displayValue " + this.$t("Report.People") + "</p>"
            }

            this.dataSource = {
                "chart": {
                    "baseFont": "Roboto",
                    "xAxisPosition": xAxisPosition,
                    "legendPosition": "bottom-center", 
                    "theme": "fusion",
                    "showValues": "0",
                    "showPlotBorder": "1",
                    "mapByCategory": "1",
                    "rotateLabels": rotateLabels,
                    "labelDisplay": labelDisplay,
                    "slantLabel": slantLabel,
                    "plottooltext": plottooltext,
                    "labelFontColor" : "8C8C8C",
                    "labelFontSize": "14",
                    "labelFontWeight": "400",
                    "legendItemFontColor" : "#5C6066",
                    "legendItemFontSize": "14",
                    "legendItemFontWeight": "400",
                    "legendItemFont": "Roboto",
                    "legendIconAlpha": 100,
                    "legendIconBorderThickness": 5,
                    "legendIconScale": "2.3",
                    "plotHighlightEffect": "fadeout",
                    "interactiveLegend": "0"
                },
                "rows": rowData,
                "columns": {
                    "column": collumn
                },
                "dataset": dataset,
                "colorRange": colorRange
            }
            this.$refs.heatmapchart.chartObj.updateChart
            this.$forceUpdate()
        }
    },
    data() {
        return {
            type: "heatmap",
            renderAt: "chart-container",
            width: "100%",
            height: "504",
            dataFormat: "json",
            dataSource: {
            }
        };
    },
    methods: {
        round5(x) {
            return Math.ceil(x / 5) * 5;
        }
    }
};
</script>

